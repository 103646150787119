import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import { styles } from "../styles";
import { textVariant } from "../utils/motion";

const experiences = [
  // {
  //   title: "Software Developer",
  //   company_name: "RTA Dubai Govt",
  //   date: "June 2024 - Present",
  //   icon: "img/rta-removebg-preview.png", // Replace with actual path to icon
  //   iconBg: "#E6DEDD",
  //   points: [
  //     "Developed and maintained web applications using .NET technologies.",
  //     "Collaborated with cross-functional teams to define, design, and ship new features.",
  //     "Ensured the performance, quality, and responsiveness of applications.",
  //   ],
  // },
  {
    title: "Programmer Analyst",
    company_name: "Koch Industries",
    date: "December 2022 – June 2024",
    icon: "img/koch-removebg-preview.png", // Replace with actual path to icon
    iconBg: "#E6DEDD",
    points: [
      "Analyzed and developed software solutions to meet business requirements.",
      "Participated in code reviews and provided constructive feedback.",
      "Worked closely with stakeholders to gather and refine requirements.",
    ],
  },
  {
    title: "Software Engineer",
    company_name: "Honeywell",
    date: "December 2021 – December 2022",
    icon: "img/honeywell-removebg-preview.png", // Replace with actual path to icon
    iconBg: "#E6DEDD",
    points: [
      "Designed and implemented software solutions for Honeywell projects.",
      "Conducted unit testing and debugging to ensure high-quality code.",
      "Collaborated with team members to deliver projects on time.",
    ],
  },
  {
    title: "Application Engineer",
    company_name: "Ooredoo LLC Oman",
    date: "March 2021 – December 2021",
    icon: "img/ooredoo-removebg-preview.png", // Replace with actual path to icon
    iconBg: "#E6DEDD",
    points: [
      "Provided on-site support and maintenance for Ooredoo applications.",
      "Troubleshot and resolved technical issues in a timely manner.",
      "Assisted in the deployment and configuration of new applications.",
    ],
  },
  {
    title: "Associate Software Engineer",
    company_name: "SL Kirloskar Software",
    date: "October 2018 – January 2021",
    icon: "img/slk-removebg-preview.png", // Replace with actual path to icon
    iconBg: "#E6DEDD",
    points: [
      "Developed software solutions as part of a team.",
      "Participated in the full software development lifecycle.",
      "Maintained and updated existing software applications.",
    ],
  },
];

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#1d1836",
        color: "#fff",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={experience.date}
      iconStyle={{ background: experience.iconBg }}
      icon={
        <div className="flex justify-center items-center w-full h-full">
          <img
            src={experience.icon}
            alt={experience.company_name}
            className="w-[60%] h-[60%] object-contain"
          />
        </div>
      }
    >
      <div>
        <h3 className="text-white text-[24px] font-bold">{experience.title}</h3>
        <p
          className="text-secondary text-[16px] font-semibold"
          style={{ margin: 0 }}
        >
          {experience.company_name}
        </p>
      </div>

      <ul className="mt-5 list-disc ml-5 space-y-2">
        {experience.points.map((point, index) => (
          <li
            key={`experience-point-${index}`}
            className="text-white-100 text-[14px] pl-1 tracking-wider"
          >
            {point}
          </li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <section className="experience section" id="experience">
      <motion.div variants={textVariant()}>
        <h2 className="section__title">Experience</h2>
        <span className="section__subtitle">Where I have worked so far</span>
      </motion.div>

      <div className="mt-20 flex flex-col">
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default Experience;
